import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Styles
import styles from './HeaderLayout.module.scss';
import homeStyles from './HomeHeaderLayout.module.scss';

// Components
import Tab from './Tab/Tab';
import HeadingText from './HeadingText';

// Assets
import CogPlayIcon from '../../../components/CogPlayIcon/CogPlayIcon';

// Hooks
import { loginUserData } from '../../../store/hooks/index';

const Header = () => {
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [location.key]);
  useEffect(() => {}, [loginUserData()?.name]);

  const renderContent = () => {
    switch (location.pathname) {
      case '/':
        return (
          <HeadingText
            MainText={`Full body. Full brain. Fun`}
            SubText="Increase focus optimize memory grow self-confidence"
            buttontxt="free PDFs and Powerpoints"
            homePath={`/`}
          />
        );
      case '/profile':
        return (
          <HeadingText
            MainText={`Hello ${loginUserData()?.name}! Welcome to your profile`}
            SubText={`All test results will be sent to your ${
              loginUserData()?.user_type === 'Children'
                ? "parent's email"
                : loginUserData()?.user_type === 'Student'
                ? "teacher's email"
                : 'email'
            }`}
            buttontxt="Edit profile"
            secondbtntxt="Setting"
            profilePath={`profile`}
          />
        );
      case '/settings':
        return (
          <HeadingText
            MainText={`Hello ${loginUserData()?.name}! Welcome to your profile`}
            SubText={`All test results will be sent to your ${
              loginUserData()?.user_type === 'Children'
                ? "parent's email"
                : loginUserData()?.user_type === 'Student'
                ? "teacher's email"
                : 'email'
            }`}
            buttontxt="Edit profile"
            secondbtntxt="Setting"
            settingPath={`settings`}
          />
        );
      case `/settings/${params.card}`:
        return (
          <HeadingText
            MainText={`Hello ${loginUserData()?.name}! Welcome to your profile`}
            SubText="All test results will be sent to your email"
            buttontxt="Edit profile"
            secondbtntxt="Setting"
            settingPath={`settings`}
          />
        );
      case '/profile/edit/new-profile':
        return <HeadingText MainText="Create your Profile" SubText="You can change this info later" />;
      case '/pricing':
        return <HeadingText MainText="Find the perfect plan for you" SubText="Simple pricing that works at any side" />;
      default:
        return <HeadingText MainText="Edit your Profile" SubText="You can change this info later" />;
    }
  };

  return (
    <>
      {location.pathname === '/' ? (
        <>
          <div className={homeStyles.headerContainer}>
            <Tab />
            <div className={homeStyles.headerMain}>
              <CogPlayIcon />
              {renderContent()}
            </div>
          </div>
        </>
      ) : // location.pathname === `/activities/${params.unit}` ||
      location.pathname === `/activities` ||
        location.pathname === `/activities/number-quiz` ||
        location.pathname === `/activities/final-quiz` ||
        location.pathname === '/activities/direction-chart' ||
        location.pathname === '/activities/count-by-seven' ||
        location.pathname === '/activities/circle-counting' ||
        location.pathname === '/activities/brain-warrior-workout' ||
        location.pathname === '/activities/finger-skip-counting' ||
        location.pathname === '/activities/figure8' ||
        location.pathname === '/activities/number-families' ||
        location.pathname === '/activities/colour-coded' ||
        location.pathname === '/activities/speed-counting' ||
        location.pathname === `/settings/${params.card}` ||
        location.pathname === '/pdf' ? (
        <Tab />
      ) : (
        <>
          <Tab />
          <div className={styles.headerContainer}>
            <div className={styles.headerMain}>
              <CogPlayIcon />
              {renderContent()}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
